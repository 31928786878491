.home-page{

}

.container{
    height: 74vh;
    width: 100%;
}

.hero-video{
    width: 100%;
    height: 100%;
}

.get-started{
   
}
.get-started .btn{
    padding: 1.1rem 2.8rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    font-size: 1.6rem;
    background: #084B55;
    border: 1px solid #ffffff;
    color: #E4B337;
    cursor: pointer;
}
.get-started .btn:hover{
   
    background: white;
    font-weight: bold;
    color: #084B55;
    border: 1px solid #084B55;
}

